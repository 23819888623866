<template>
  <v-app>
    <div id="app">
      <router-view />
    </div>
  </v-app>
</template>

<script>

export default {
  name: "App",
  metaInfo: {
    title: 'Texas de France',
    htmlAttrs: { lang: 'fr' },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    ],
  }
};
</script>
