<template>
  <v-row justify="center">
    <v-dialog
        v-model="visible"
        max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          {{ title }}
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              v-for="(action, idx) in actions"
              :key="idx"
              color="green darken-1"
              text
              @click="onClick(action)"
          >
            {{  action }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
name: "ConfirmDialog",
  props: {
    visible: Boolean,
    title: String,
    message: String,
    actions: Array,
  },
  methods : {
    onClick(action){
      this.$emit('action', action)
    }
  }
}
</script>

<style scoped>

</style>
