import axios from "axios";

const $http = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : "https://www.texasdefrance.com"
});

const auth = {
  login: function(email, password) {
    return $http
      .request({
        method: "post",
        url: "/login",
        withCredentials: true,
        data: {
          email: email,
          password: password
        }
      })
      .then(response => {
        return Promise.resolve(response.data);
      });
  }
};

const references = {
  getAll() {
    return $http
      .request({
        method: "get",
        url: "/ref",
        withCredentials: true,
        credentials: "same-origin",
        cookie: document.cookie
      })
      .then(response => {
        return Promise.resolve(response.data);
      });
  },

  upload(formData) {
    return $http
      .request({
        method: "post",
        url: "/ref",
        withCredentials: true,
        credentials: "same-origin",
        cookie: document.cookie,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        return Promise.resolve(response.data);
      });
  },

  getAssociatedFiles(ref) {
    return $http
      .request({
        method: "get",
        url: "/ref/" + ref,
        withCredentials: true,
        credentials: "same-origin",
        cookie: document.cookie
      })
      .then(response => {
        return Promise.resolve(response.data);
      });
  }
};

const notices = {
  getAll() {
    return $http
      .request({
        method: "get",
        url: "/files",
        withCredentials: true,
        credentials: "same-origin",
        cookie: document.cookie
      })
      .then(response => {
        return Promise.resolve(response.data);
      });
  },
  upload(formData) {
    return $http
      .request({
        method: "post",
        url: "/files",
        withCredentials: true,
        credentials: "same-origin",
        cookie: document.cookie,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        return Promise.resolve(response.data);
      });
  },
  remove(fileId) {
    return $http
      .request({
        method: "delete",
        url: "/files/" + fileId,
        withCredentials: true,
        credentials: "same-origin",
        cookie: document.cookie
      })
      .then(response => {
        return Promise.resolve(response.data);
      });
  }
};

const help = {
  getAll(lang) {
    return $http
      .request({
        method: "get",
        url: "/help?lang=" + lang,
        withCredentials: true,
        credentials: "same-origin",
        cookie: document.cookie
      })
      .then(response => {
        return Promise.resolve(response.data);
      });
  },
  delete(id) {
    return $http
      .request({
        method: "delete",
        url: "/help/" + id,
        withCredentials: true,
        credentials: "same-origin",
        cookie: document.cookie
      })
      .then(response => {
        return Promise.resolve(response.data);
      });
  },
  update(id, content) {
    return $http
      .request({
        method: "put",
        url: "/help/" + id,
        withCredentials: true,
        credentials: "same-origin",
        cookie: document.cookie,
        data: content
      })
      .then(response => {
        return Promise.resolve(response.data);
      });
  },
  create(content) {
    return $http
      .request({
        method: "post",
        url: "/help",
        withCredentials: true,
        credentials: "same-origin",
        cookie: document.cookie,
        data: content
      })
      .then(response => {
        return Promise.resolve(response.data);
      });
  }
};

export default {
  auth,
  references,
  notices,
  help
};
