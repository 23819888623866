// supportDidSelectRef

import api from "../plugins/api";

const state = {
  /**
   * The full list of references and associated data
   */
  referencesName: [],

  selectedRef: "",

  filesForSelectedRef: []
};

const getters = {
  supportSelectedRef: state => state.selectedRef,
  referencesName: state => state.referencesName,
  supportFiles: state => state.filesForSelectedRef
};

const actions = {

  // eslint-disable-next-line no-unused-vars
  async getFiles({ commit }, reference)
  {
    let files = await api.references.getAssociatedFiles(reference);
    return files;
  },

  async fetchReferencesName({ commit }) {
    const response = await api.references.getAll();
    commit("setReferenceNames", response);
  },

  async supportDidSelectRef({ commit }, reference) {
    await commit("set_selected_ref", reference);
    let files = await api.references.getAssociatedFiles(reference);
    await commit("set_associated_file", files);
  }
};

const mutations = {
  setReferenceNames(state, payload) {
    state.referencesName = payload.map(x => x.reference);
  },

  set_selected_ref(state, payload) {
    state.selectedRef = payload;
  },

  set_associated_file(state, payload) {
    state.filesForSelectedRef = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
