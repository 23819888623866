import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../layouts/Main/Index.vue";
import Admin from "../layouts/Admin/Index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/admin/",
    component: Admin,
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () => import("@/views/admin/Dashboard.vue"),
        meta: {}
      },
      {
        path: "refs",
        name: "References",
        component: () => import("@/views/admin/Refs.vue"),
        meta: {}
      },
      {
        path: "notices",
        name: "UserManual",
        component: () => import("@/views/admin/Notices.vue"),
        meta: {}
      },
      {
        path: "faq",
        name: "FaqEditor",
        component: () => import("@/views/admin/FAQEditor"),
        meta: {}
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/",
    component: Home,
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/views/Home.vue"),
        meta: {}
      },

      {
        path: "/download",
        name: "Download",
        component: () => import("@/views/Download.vue"),
        meta: {}
      },

      {
        path: "/faq",
        name: "faq",
        component: () => import("@/views/Helpdesk.vue"),
        meta: {}
      },

      {
        path: "/login",
        name: "Login",
        component: () => import("@/views/Login.vue"),
        meta: {}
      },
      {
        path: "/:id",
        name: "FourOhFour",
        component: () => import("@/views/QRCode"),
        meta: {}
      },
      {
        path: "*",
        name: "FourOhFour",
        component: () => import("@/views/404.vue"),
        meta: {}
      }
    ]
  },
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  /**
   * if the page requieres the user to be logged in and he is not, redirect to login page
   */
  if (
    to.matched.some(record => record.meta.requiresAuth) &&
    localStorage.getItem("token") === null
  ) {
    next({
      path: "/login",
      params: { nextUrl: to.fullPath }
    });
  } else if (
    /**
     * if page requieres to be an admin  and the user is not, redirect to 404
     */
    to.matched.some(record => record.meta.requiresAuth) &&
    localStorage.getItem("admin") !== "1"
  ) {
    next("Home");
  } else {
    next();
  }
});

export default router;
