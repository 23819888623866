import api from "../plugins/api";

const state = {
  /**
   * the user name
   */
  username: "",

  /**
   * The user's access token usedfor all API calls
   */
  acces_token: null,

  isAdmin: 0
};

const getters = {
  isLoggedIn: state => state.acces_token !== null,
  username: state => state.username,
  isAdmin: state => state.isAdmin
};

const actions = {
  logInUser({ commit }, payload) {
    const email = payload.email;
    const password = payload.password;

    return api.auth
      .login(email, password)

      .then(apiResponse => {
        commit("loginSuccess", apiResponse);
      });
  }
};

const mutations = {
  loginSuccess(state, payload) {
    state.username = payload.userName;
    state.isAdmin = payload.isAdmin;
    state.acces_token = payload.token;

    localStorage.setItem("admin", payload.isAdmin ? "1" : "0");
    localStorage.setItem("token", payload.token);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
