import Vue from "vue";
import Vuex from "vuex";

import admin from "@/store/Admin";
import support from "@/store/Support";
import user from "@/store/User";

Vue.use(Vuex);

export default new Vuex.Store({
  modules : {
    admin,
    support,
    user
  }
});
