import api from '../plugins/api';

const state = {
  /**
   * fill in with the user folders
   */
  folders: [
    {
      _id: 1,
      name: "Références"
    },
    {
      _id: 2,
      name: "Notices TH"
    }
  ],

  /**
   * set the selected folder
   */
  selectedFolder: {},


  /**
   * The list of references and associated data
   */
  references : [],

  /**
   * the list of models notices
   */
  models : [],



};

const getters = {
  folders: state => state.folders,
  selectedFolder: state => state.selectedFolder,
  references: state => state.references,
  models: state => state.models
};

const actions = {

  changeFolder({ commit }, payload) {
    const folder = payload.folder;
    commit("change_current_folder", folder);
  },

  async fetchReferences({ commit }){
    const response = await api.references.getAll();
    commit("setReferences", response);
  },

  async upload_csv({commit}, payload){
    await api.references.upload(payload);
    const response = await api.references.getAll();
    commit("setReferences", response);
  },

  async fetchModels({commit}){
    const response = await api.notices.getAll();
    commit("setModels", response);
  },

  async upload_notice({commit}, payload){
    await api.notices.upload(payload);
    const response = await api.notices.getAll();
    commit("setModels", response);
  },

  async deleteFile({commit}, fileId){
    await api.notices.remove(fileId);
    const response = await api.notices.getAll();
    commit("setModels", response);
  }
};

const mutations = {
  change_current_folder(state, payload) {
    state.selectedFolder = payload;
  },
  setReferences(state, payload) {
    console.log(payload);
    state.references = payload;
  },
  setModels(state, payload) {
    console.log(payload);
    state.models = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
