import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

// import extra capabilities
import './plugins/font-awesome'
import './plugins/base'
import './plugins/meta'
import './plugins/webfontloader'

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
